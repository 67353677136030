import styled from "styled-components";
import { Color } from "../../../layout/GlobalStyles/Color";
import { Dimensions } from "../../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../../layout/GlobalStyles/Viewport";

export const ContentRoot = styled.div`
    display: block;
    max-width: ${Dimensions.MaxContentWidth}px;
    margin: 0px auto;
`;
export const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    flex-direction: column;
    @media screen and (max-width: ${Viewport.Large}px) {
    }
`;

export const SelectorRoot = styled.ul`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: ${Dimensions.GridUnit * 1}px;
    margin: 0px auto;
    margin-bottom: ${Dimensions.GridUnit * 2}px;
`;

interface IItemProps {
    readonly isActive: boolean;
}
export const SelectorItem = styled.li<IItemProps>`
    display: block;
    cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.8)};
    box-sizing: border-box;
    border: ${({ isActive }) => (isActive ? `4px solid ${Color.Neutral}` : `4px solid ${Color.Background}`)};
    transition: all 0.2s ease-in-out;
    :hover {
        opacity: ${({ isActive }) => (isActive ? 0.8 : 1)};
    }
`;
