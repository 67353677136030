import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../../services/Constants/HeadbotRoute";
import { PrimaryButtonLink } from "../../../atoms/ButtonLink/PrimaryButtonLink";
import { H1 } from "../../../atoms/H1/H1";
import { H2 } from "../../../atoms/H2/H2";
import { Benefit } from "./Benefit";
import { Content, ContentRoot, ImageRoot, Root, TextRoot, TextWrapper } from "./HeroSection.styles";

export const HeroSection: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <ContentRoot>
                <Content>
                    <TextRoot>
                        <TextWrapper>
                            <H1>{t("buffPage.elon.title")}</H1>
                            <H2>{t("buffPage.elon.description")}</H2>
                            <ul>
                                <Benefit>{t("buffPage.valueProp.benefit1")}</Benefit>
                                <Benefit>{t("buffPage.valueProp.benefit2")}</Benefit>
                                <Benefit>{t("buffPage.valueProp.benefit3")}</Benefit>
                                <Benefit>{t("buffPage.valueProp.benefit4")}</Benefit>
                            </ul>
                            <PrimaryButtonLink to={HeadbotRoute.CreateCouples}>{t("buffPage.create")}</PrimaryButtonLink>
                        </TextWrapper>
                    </TextRoot>
                    <ImageRoot>
                        <StaticImage
                            src="./headbot.png"
                            alt={t("buffPage.heroImageAlt")}
                            transformOptions={{ fit: "cover" }}
                            width={512}
                            objectPosition="50% 10%"
                            style={{ borderRadius: "4%", overflow: "hidden" }}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                    </ImageRoot>
                </Content>
            </ContentRoot>
        </Root>
    );
};
