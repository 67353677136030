import { useState, useEffect } from "react";

export const useQueryParam = (key: string): [string | null, (value: string | null) => void] => {
    const [queryParamValue, setQueryParamValue] = useState<string | null>(null);

    useEffect(() => {
        const getQueryParamValue = () => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            return urlSearchParams.get(key);
        };

        const value = getQueryParamValue();
        setQueryParamValue(value);
    }, [key]);

    const setQueryParam = (value: string | null) => {
        const url = new URL(window.location.href);
        const urlSearchParams = url.searchParams;

        if (value === null) {
            urlSearchParams.delete(key);
        } else {
            urlSearchParams.set(key, value);
        }

        const newUrl = url.toString();

        window.history.replaceState({}, "", newUrl);
        setQueryParamValue(value);
    };

    return [queryParamValue, setQueryParam];
};
