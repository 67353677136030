import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../BuffPage.styles";
import { Content, ContentRoot, ImageDescription, ImageRoot, Root } from "./ObamaSection.styles";

interface IProps {}
export const ObamaSection: React.FC<IProps> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <ContentRoot>
                <Title>{t("buffPage.obama.title")}</Title>
                <Content>
                    <ImageRoot>
                        <StaticImage
                            src="./barack1.jpg"
                            alt={t("buffPage.obama.normalObamaAlt")}
                            layout="constrained"
                            width={468}
                            height={468}
                            transformOptions={{ fit: "cover" }}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                        <ImageDescription>{t("buffPage.obama.description1")}</ImageDescription>
                    </ImageRoot>
                    <ImageRoot>
                        <StaticImage
                            src="./obama-watermark.png"
                            alt={t("buffPage.obama.buffObamaAlt")}
                            height={468}
                            transformOptions={{ fit: "cover" }}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                        <ImageDescription>{t("buffPage.obama.description2")}</ImageDescription>
                    </ImageRoot>
                </Content>
            </ContentRoot>
        </Root>
    );
};
