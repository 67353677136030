import * as React from "react";
import { useTranslation } from "react-i18next";
import { H1 } from "../../../atoms/H1/H1";
import { Root, ContentRoot, NumberPics, NumberUsers } from "./Counter.styles";

export const Counter: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <ContentRoot>
                <H1>
                    <NumberPics>189,292 </NumberPics>
                    {t("buffPage.counter.part1")}
                    <NumberUsers> 1,279 </NumberUsers>
                    {t("buffPage.counter.part2")}
                </H1>
            </ContentRoot>
        </Root>
    );
};
