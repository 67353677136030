import styled from "styled-components";
import { H2 } from "../../../atoms/H2/H2";
import { Color } from "../../../layout/GlobalStyles/Color";
import { Dimensions } from "../../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../../layout/GlobalStyles/Viewport";

export const TestimonialContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: ${Dimensions.GridUnit * 4}px;
    padding-bottom: ${Dimensions.GridUnit * 10}px;
    margin-bottom: ${Dimensions.GridUnit * 10}px;
    & > *:not(:last-child) {
        margin-bottom: ${Dimensions.GridUnit * 4}px;
    }
`;

export const TestimonialImage = styled.div`
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    & img {
        max-width: 100%;
        height: auto;
        border-radius: 4%;
    }
`;

export const Title = styled(H2)`
    text-align: center;
    padding-top: ${Dimensions.GridUnit * 4}px;
    padding-bottom: ${Dimensions.GridUnit * 4}px;
    @media screen and (max-width: ${Viewport.Medium}px) {
        text-align: center;
        padding-left: ${Dimensions.GridUnit * 2}px;
    }
`;
export const TestimonialProfileImage = styled.div`
    width: ${Dimensions.GridUnit * 5}px;
    height: ${Dimensions.GridUnit * 5}px;
    border-radius: 50%;
    margin-right: ${Dimensions.GridUnit}px;
`;

export const TestimonialTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const TestimonialName = styled.div`
    font-weight: "bold";
`;

export const TestimonialTitle = styled.div`
    font-size: 12px;
    color: Col;
`;

export const TestimonialCompany = styled.div`
    font-size: 8px;
    color: ${Color.LightGray};
    margin-bottom: ${Dimensions.GridUnit}px;
`;

export const TestimonialContent = styled.div`
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin: ${Dimensions.GridUnit * 2}px;
`;

export const TestimonialPostContainerContainer = styled.div`
  padding: ${Dimensions.GridUnit * 1}px;
};
`;

export const TestimonialPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px ${Color.DarkGray};
  padding: ${Dimensions.GridUnit * 1}px;
};
`;

export const TestimonialPostHeader = styled.div`
    display: flex;
    align-items: center;
    padding: ${Dimensions.GridUnit * 2}px;
`;

export const TestimonialPostContent = styled.div`
    padding: ${Dimensions.GridUnit * 2}px;
    align-items: center;
    justfiy-content: center;
    height: auto;
`;

export const TestimonialPostFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${Dimensions.GridUnit * 2}px;
    background-color: ${Color.LightGray};
    color: white;
    border-bottom-left-radius: 5;
    border-bottom-right-radius: 5;
`;

export const TestimonialDate = styled.div`
    font-size: 8px;
`;

export const TestimonialLikes = styled.div`
    display: flex;
    align-items: center;
`;

export const TestimonialLikesIcon = styled.span`
    margin-right: ${Dimensions.GridUnit / 2}px;
`;

export const TestimonialLikesCount = styled.span`
    font-size: 8px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    max-width: ${Dimensions.MaxContentWidth * 0.8}px;
    margin: 0px auto;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    height: auto;
    padding: ${Dimensions.GridUnit * 2}px;
`;

export const Root = styled.div`
    display: block;
`;
