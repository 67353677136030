import { TelemetryEvent } from "@headbot/library";
import { StaticImage } from "gatsby-plugin-image";
import { IStaticImageProps } from "gatsby-plugin-image/dist/src/components/static-image.server";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { telemetry } from "../../../../services/Telemetry/Telemetry";
import { H2 } from "../../../atoms/H2/H2";
import { BezosAnimeGallery } from "../StaticGallery/BezosAnimeGallery";
import { BezosGallery } from "../StaticGallery/BezosGallery";
import { ElonGallery } from "../StaticGallery/ElonGallery";
import { KendallAnimeGallery } from "../StaticGallery/KendallAnimeGallery";
import { ZuckAnimeGallery } from "../StaticGallery/ZuckAnimeGallery";
import { ZuckGallery } from "../StaticGallery/ZuckGallery";
import { Content, ContentRoot, SelectorItem, SelectorRoot } from "./GalleryIndex.styles";

enum BuffGallery {
    Zuck = "zuck",
    Bezos = "bezos",
    Elon = "elon",
}

enum AnimeGallery {
    ZuckAnime = "zuck-anime",
    BezosAnime = "bezos-anime",
    KendallAnime = "kendall-anime",
}

export enum GalleryType {
    Anime,
    Buff,
    Couples,
}

interface IGalleryData {
    readonly component: React.ReactNode;
    readonly selectorImageComponent: React.ReactNode;
}

const galleryDefaults: Partial<IStaticImageProps> = {
    width: 256,
    height: 256,
    transformOptions: { fit: "cover" },
    style: { overflow: "hidden" },
};

// selector image must be a reactNode instead of being dynamically generated so build can prerender
const buffGalleryMetadata: Record<BuffGallery, IGalleryData> = {
    [BuffGallery.Elon]: {
        component: <ElonGallery />,
        selectorImageComponent: (
            <StaticImage src="./elon.jpg" imgStyle={{ borderRadius: "4%" }} alt={"placeholderAlt"} {...galleryDefaults} />
        ),
    },
    [BuffGallery.Bezos]: {
        component: <BezosGallery />,
        selectorImageComponent: (
            <StaticImage src="./bezos.jpg" imgStyle={{ borderRadius: "4%" }} alt={"placeholderAlt"} {...galleryDefaults} />
        ),
    },
    [BuffGallery.Zuck]: {
        component: <ZuckGallery />,
        selectorImageComponent: (
            <StaticImage src="./zuck.jpg" imgStyle={{ borderRadius: "4%" }} alt={"placeholderAlt"} {...galleryDefaults} />
        ),
    },
};

const animeGalleryMetadata: Record<AnimeGallery, IGalleryData> = {
    [AnimeGallery.KendallAnime]: {
        component: <KendallAnimeGallery />,
        selectorImageComponent: (
            <StaticImage src="./kendall.jpeg" imgStyle={{ borderRadius: "4%" }} alt={"placeholderAlt"} {...galleryDefaults} />
        ),
    },
    [AnimeGallery.BezosAnime]: {
        component: <BezosAnimeGallery />,
        selectorImageComponent: (
            <StaticImage src="./bezos.jpg" imgStyle={{ borderRadius: "4%" }} alt={"placeholderAlt"} {...galleryDefaults} />
        ),
    },
    [AnimeGallery.ZuckAnime]: {
        component: <ZuckAnimeGallery />,
        selectorImageComponent: (
            <StaticImage src="./zuck.jpg" imgStyle={{ borderRadius: "4%" }} alt={"placeholderAlt"} {...galleryDefaults} />
        ),
    },
};

interface IProps {
    readonly galleryType: GalleryType;
}

export const GalleryIndex: React.FC<IProps> = ({ galleryType }) => {
    const galleryMetadata = galleryType === GalleryType.Anime ? animeGalleryMetadata : buffGalleryMetadata;
    const initialState = galleryType === GalleryType.Anime ? AnimeGallery.BezosAnime : BuffGallery.Elon;
    const [selectedGallery, setSelectedGallery] = useState<BuffGallery | AnimeGallery>(initialState);
    const { component } = galleryMetadata[selectedGallery];
    const [t] = useTranslation();
    const gallerySelector = useMemo(() => {
        return Object.keys(galleryMetadata).map((key) => {
            const data = galleryMetadata[key];
            const { selectorImageComponent } = data;
            const onClick = async () => {
                setSelectedGallery(key as BuffGallery | AnimeGallery);
                await telemetry.Log(TelemetryEvent.GallerySwitched, key);
            };
            const isActive = selectedGallery === key;
            return (
                <SelectorItem key={key} onClick={onClick} isActive={isActive}>
                    {selectorImageComponent}
                </SelectorItem>
            );
        });
    }, [selectedGallery]);
    return (
        <ContentRoot>
            <Content>
                <H2>{t("gallery.title")}</H2>
                <SelectorRoot>{gallerySelector}</SelectorRoot>
                {component}
            </Content>
        </ContentRoot>
    );
};
