import styled from "styled-components";
import { H3 } from "../../../atoms/H3/H3";
import { Dimensions } from "../../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../../layout/GlobalStyles/Viewport";

export const Root = styled.div`
    display: block;
    width: 100%;
`;
export const ContentRoot = styled.div`
    display: block;
    max-width: ${Dimensions.MaxContentWidth}px;
    margin: 0px auto;
`;
export const Content = styled.div`
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, auto);
    box-sizing: border-box;
    @media screen and (max-width: ${Viewport.Large}px) {
        grid-template-rows: auto;
        grid-template-columns: auto;
        grid-row-gap: 0px;
        display: flex;
        flex-direction: column;
        height: auto;
    }
`;

export const ImageRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: ${Viewport.Large}px) {
        max-width: 300px;
        margin: 0px auto;
    }
`;

export const ImageDescription = styled(H3)`
    padding-top: ${Dimensions.GridUnit * 2}px;
    padding-bottom: ${Dimensions.GridUnit * 4}px;
`;
