import styled from "styled-components";
import { Color } from "../../../layout/GlobalStyles/Color";
import { Viewport } from "../../../layout/GlobalStyles/Viewport";

export const Root = styled.div`
    background: ${Color.AboveFoldBackground};
    display: block;
    width: 100%;
`;

export const ContentRoot = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    margin-bottom: 5vh;
    width: 30%;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: ${Viewport.ExtraLarge}px) {
        width: 70%;
    }
    @media screen and (max-width: ${Viewport.ExtraLarge}px) {
        width: 50%;
    }
    @media screen and (max-width: ${Viewport.Large}px) {
        grid-template-rows: auto;
        grid-template-columns: auto;
        grid-row-gap: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
    }
`;

export const NumberPics = styled.div`
    display: inline;
    background: #ffb76b;
    background: linear-gradient(to right, #ffb76b 0%, #ffa73d 30%, #ff7c00 60%, #ff7f04 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const NumberUsers = styled.div`
    display: inline;
    background: #0dcf06;
    background: radial-gradient(ellipse farthest-corner at center center, #0dcf06 0%, #8dcf80 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
