import * as React from "react";
import { useEffect } from "react";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { SessionStorageKey } from "../../../services/Constants/SessionStorageKey";
import { QueryParamCaptureKey } from "./QueryParamCaptureKey";

interface IProps {
    readonly queryParamKey: QueryParamCaptureKey;
}
export const CaptureQueryParamInSessionStorage: React.FC<IProps> = ({ queryParamKey }) => {
    const [couponCode] = useQueryParam(queryParamKey);
    const [_, setCouponCode] = useSessionStorage(SessionStorageKey.CouponCode, couponCode);
    useEffect(() => {
        if (couponCode !== null && couponCode.length > 0) {
            setCouponCode(couponCode);
        }
    }, [couponCode, setCouponCode]);
    return null;
};
