import { IconCircleCheck } from "@tabler/icons-react";
import * as React from "react";
import { Color } from "../../../layout/GlobalStyles/Color";
import { BenefitItemContainer, BenefitP } from "./HeroSection.styles";

export const Benefit: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <BenefitItemContainer>
            <IconCircleCheck size={28} strokeWidth={2} color={Color.Green100} />
            <BenefitP>{children}</BenefitP>
        </BenefitItemContainer>
    );
};
