import styled from "styled-components";
import { P } from "../../../atoms/P/P";
import { Color } from "../../../layout/GlobalStyles/Color";
import { Dimensions } from "../../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../../layout/GlobalStyles/Viewport";

export const Root = styled.div`
    background: ${Color.AboveFoldBackground};
    display: block;
    width: 100%;
`;
export const ContentRoot = styled.div`
    display: block;
    max-width: ${Dimensions.MaxContentWidth}px;
    margin: 0px auto;
`;
export const Content = styled.div`
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, auto);
    box-sizing: border-box;
    @media screen and (max-width: ${Viewport.Large}px) {
        grid-template-rows: auto;
        grid-template-columns: auto;
        grid-row-gap: 0px;
        display: flex;
        flex-direction: column;
        height: auto;
    }
`;

export const ImageRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: ${Viewport.Large}px) {
        max-width: 300px;
        margin: 0px auto;
        margin-top: ${Dimensions.GridUnit * 1}px;
        order: -1;
    }
`;

export const TextRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: ${Viewport.Large}px) {
        justify-content: flex-start;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: ${Dimensions.GridUnit * 2}px;
    padding-right: ${Dimensions.GridUnit * 2}px;
    gap: ${Dimensions.GridUnit * 2}px;
    @media screen and (max-width: ${Viewport.Large}px) {
        justify-content: flex-start;
        margin: 0px auto;
        margin-top: ${Dimensions.GridUnit * 4}px;
        margin-bottom: ${Dimensions.GridUnit * 4}px;
        max-width: 512px;
        box-sizing: border-box;
    }
`;

export const BenefitItemContainer = styled.li`
    display: flex;
    align-items: center;
`;

export const BenefitP = styled(P)`
    margin-left: 5px;
`;
