import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { CaptureQueryParamInSessionStorage } from "../../atoms/CaptureQueryParamInSessionStorage/CaptureQueryParamInSessionStorage";
import { QueryParamCaptureKey } from "../../atoms/CaptureQueryParamInSessionStorage/QueryParamCaptureKey";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { GalleryIndex, GalleryType } from "../GalleryPage/GalleryIndex/GalleryIndex";
import { Counter } from "./Counter/Counter";
import { HeroSection } from "./HeroSection/HeroSection";
import { ObamaSection } from "./ObamaSection/ObamaSection";
import { Testimonials } from "./Testimonial/Testimonial";

export const CouplesPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("couplesPage.description");
    const title = t("couplesPage.title");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.Buff}>
            <CaptureQueryParamInSessionStorage queryParamKey={QueryParamCaptureKey.Coupon} />
            <HeroSection />
            <Counter />
            <GalleryIndex galleryType={GalleryType.Couples} />
            <ObamaSection />
            <Testimonials />
        </ScrollablePageTemplate>
    );
};
