import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";
import { ImageRoot, Root } from "./StaticGallery.styles";

interface IProps {
    readonly alt: string;
    readonly galleryData: {
        readonly allFile: {
            readonly edges: readonly {
                readonly node: {
                    readonly id: string;
                    readonly childImageSharp: {
                        readonly gatsbyImageData: IGatsbyImageData;
                    } | null;
                };
            }[];
        };
    };
}

export const StaticGallery: React.FC<IProps> = ({ alt, galleryData }) => {
    const images = galleryData.allFile.edges.map((edge) => {
        const src = edge.node.childImageSharp?.gatsbyImageData;
        if (src === undefined) {
            return;
        }
        return (
            <ImageRoot key={edge.node.id}>
                <GatsbyImage image={src} alt={alt} imgStyle={{ borderRadius: "4%" }} />
            </ImageRoot>
        );
    });
    return <Root>{images}</Root>;
};
