import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { StaticGallery } from "./StaticGallery";

export const KendallAnimeGallery: React.FC = () => {
    const staticData = useStaticQuery<Queries.KendallAnimeGalleryQuery>(graphql`
        query KendallAnimeGallery {
            allFile(filter: { relativeDirectory: { eq: "kendall-anime" } }) {
                edges {
                    node {
                        id
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 150, height: 150, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
            }
        }
    `);

    const [t] = useTranslation();

    return <StaticGallery galleryData={staticData} alt={t("publicGallery.elon.alt")} />;
};
