import styled from "styled-components";

export const Root = styled.ul`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    flex-direction: row;
    margin: 0px auto;
    justify-content: center;
`;
export const ImageRoot = styled.li`
    margin: 10px;
`;
