import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
    Container,
    Root,
    TestimonialContent,
    TestimonialImage,
    TestimonialName,
    TestimonialPostContainer,
    TestimonialPostContainerContainer,
    TestimonialPostContent,
    TestimonialPostHeader,
    TestimonialProfileImage,
    TestimonialTextContainer,
    Title,
} from "./Testimonial.styles";

interface ITestimonialProps {
    name: string;
    testimonial: string;
    image: React.FC;
    profile: React.FC;
}
const Man1: React.FC = () => {
    return <StaticImage src="./man1.png" alt="man1" width={128} height={128} imgStyle={{ borderRadius: "50%" }} />;
};
const Man1Buff: React.FC = () => {
    return (
        <StaticImage
            src="./man1-buff.jpg"
            alt="man1-buff"
            width={256}
            height={384}
            placeholder="blurred"
            layout="constrained"
            imgStyle={{ borderRadius: "4%" }}
        />
    );
};

const Man2: React.FC = () => {
    return <StaticImage src="./man2.png" alt="man2" width={128} height={128} imgStyle={{ borderRadius: "50%" }} />;
};
const Man2Buff: React.FC = () => {
    return (
        <StaticImage
            src="./man2-buff.jpg"
            alt="man2-buff"
            width={256}
            height={384}
            placeholder="blurred"
            layout="constrained"
            imgStyle={{ borderRadius: "4%" }}
        />
    );
};

const Man3: React.FC = () => {
    return <StaticImage src="./man3.png" alt="man3" width={128} height={128} imgStyle={{ borderRadius: "50%" }} />;
};
const Man3Buff: React.FC = () => {
    return (
        <StaticImage
            src="./man3-buff.jpg"
            alt="man3-buff"
            width={256}
            height={384}
            placeholder="blurred"
            layout="constrained"
            imgStyle={{ borderRadius: "4%" }}
        />
    );
};

const Man4: React.FC = () => {
    return <StaticImage src="./man4.png" alt="man4" width={128} height={128} imgStyle={{ borderRadius: "50%" }} />;
};
const Man4Buff: React.FC = () => {
    return (
        <StaticImage
            src="./man4-buff.jpg"
            alt="man4-buff"
            width={256}
            height={384}
            placeholder="blurred"
            layout="constrained"
            imgStyle={{ borderRadius: "4%" }}
        />
    );
};

const Woman1: React.FC = () => {
    return <StaticImage src="./woman1.png" alt="woman1" width={128} height={128} imgStyle={{ borderRadius: "50%" }} />;
};
const Woman1Buff: React.FC = () => {
    return (
        <StaticImage
            src="./woman1-buff.jpg"
            alt="woman1-buff"
            width={256}
            height={384}
            placeholder="blurred"
            layout="constrained"
            imgStyle={{ borderRadius: "4%" }}
        />
    );
};

const Woman2: React.FC = () => {
    return <StaticImage src="./woman2.png" alt="woman2" width={128} height={128} imgStyle={{ borderRadius: "50%" }} />;
};
const Woman2Buff: React.FC = () => {
    return (
        <StaticImage
            src="./woman2-buff.jpg"
            alt="woman2-buff"
            width={256}
            height={384}
            placeholder="blurred"
            layout="constrained"
            imgStyle={{ borderRadius: "4%" }}
        />
    );
};

const Testimonial: React.FC<ITestimonialProps> = ({ name, testimonial, image, profile }) => {
    const Profile = profile;
    const Image = image;
    return (
        <TestimonialPostContainerContainer>
            <TestimonialPostContainer>
                <TestimonialPostHeader>
                    <TestimonialProfileImage>
                        <Profile />
                    </TestimonialProfileImage>
                    <TestimonialTextContainer>
                        <TestimonialName>{name}</TestimonialName>
                    </TestimonialTextContainer>
                </TestimonialPostHeader>
                <TestimonialPostContent>
                    <TestimonialImage>
                        <Image />
                    </TestimonialImage>
                    <TestimonialContent>{testimonial}</TestimonialContent>
                </TestimonialPostContent>
            </TestimonialPostContainer>
        </TestimonialPostContainerContainer>
    );
};

export const Testimonials: React.FC = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <Title>{t("buffPage.testimonials.title")}</Title>
            <Container>
                <Testimonial
                    name={t("buffPage.testimonials.man1")}
                    testimonial={t("buffPage.testimonials.testimonial1")}
                    image={Man1Buff}
                    profile={Man1}
                />
                <Testimonial
                    name={t("buffPage.testimonials.man2")}
                    testimonial={t("buffPage.testimonials.testimonial2")}
                    image={Man2Buff}
                    profile={Man2}
                />
                <Testimonial
                    name={t("buffPage.testimonials.woman5")}
                    testimonial={t("buffPage.testimonials.testimonial5")}
                    image={Woman1Buff}
                    profile={Woman1}
                />
            </Container>
            <Container>
                <Testimonial
                    name={t("buffPage.testimonials.man3")}
                    testimonial={t("buffPage.testimonials.testimonial3")}
                    image={Man3Buff}
                    profile={Man3}
                />
                <Testimonial
                    name={t("buffPage.testimonials.man4")}
                    testimonial={t("buffPage.testimonials.testimonial4")}
                    image={Man4Buff}
                    profile={Man4}
                />
                <Testimonial
                    name={t("buffPage.testimonials.woman6")}
                    testimonial={t("buffPage.testimonials.testimonial6")}
                    image={Woman2Buff}
                    profile={Woman2}
                />
            </Container>
        </Root>
    );
};
